import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

export interface PieChartProp {
  title: string;
  amount: number;
}

export interface PieChartProps {
  data: PieChartProp[];
  title: string;
  subtitle: string;
}

const COLORS = ['#2564ec', '#e11d48', '#16a34b', '#18181b'];

const ExercisesPieChart: React.FC<PieChartProps> = ({ title, subtitle, data }) => {
  return (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
        <h3 className="tracking-tight text-base font-normal">{title}</h3>
      </div>
      <div className="p-6 pt-0">
        <p className="text-xs text-muted-foreground mb-8">{subtitle}</p>
        <div className="mt-4 h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie data={data} cx="50%" cy="50%" labelLine={false} outerRadius={80} fill="#8884d8" dataKey="value">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="rounded-lg border bg-background p-2 shadow-sm">
                        <div className="grid grid-cols-2 gap-2">
                          <div className="flex flex-col">
                            <span className="text-[0.70rem] uppercase text-muted-foreground">
                              {payload[0].payload.name}
                            </span>
                            <span className="text-sm">{payload[0].payload.amount}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return null;
                }}
              />{' '}
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ExercisesPieChart;
